import {
	MathUtils,
    Vector3
} from 'three';

export default class Utilities  {
    constructor() {

        console.log ('utilities constructor (+)');

        this.wgs84 = {
            RADIUS: 6378137,
            FLATTENING_DENOM: 298.257223563,
            /**
             * @return {number}
             */
            FLATTENING: function () {
                return 1 / this.FLATTENING_DENOM;
            },
            /**
             * @return {number}
             */
            POLAR_RADIUS: function () {
                return this.RADIUS * (1 - this.FLATTENING());
            },
        
            // useful methods to wgs48
            /**
             * @return {number}
             */
            RADIUS_SQRT: function () {
                return this.RADIUS * this.RADIUS;
            },
            /**
             * @return {number}
             */
            POLAR_RADIUS_SQRT: function () {
                return this.POLAR_RADIUS() * this.POLAR_RADIUS();
            },
            /**
             * @return {number}
             */
            ECCENTRICITY2: function () {
                return (2 - this.FLATTENING()) * this.FLATTENING();
            },
            /**
             * @return {number}
             */
            ECCENTRICITY: function () {
                return Math.sqrt((this.RADIUS_SQRT() - this.POLAR_RADIUS_SQRT()) / this.RADIUS_SQRT());
            },
            /**
             * @return {number}
             */
            ECCENTRICITY_PRIME: function () {
                return Math.sqrt((this.RADIUS_SQRT() - this.POLAR_RADIUS_SQRT()) / this.POLAR_RADIUS_SQRT());
            }
        };
        
        console.log ('utilities constructor (-)');
    }
    // Converts an angle in radians to degrees.
    degrees(angle) {
        return angle * (180 / Math.PI);
    };    
    getN(latitude) {
        var sinlatitude = Math.sin(latitude);
        var denom = Math.sqrt(1 - this.wgs84.ECCENTRICITY() * this.wgs84.ECCENTRICITY() * sinlatitude * sinlatitude);
        var N = this.wgs84.RADIUS / denom;
        return N;
    };    
    // convert Longtitute, Latitude, Altitude to ECEF (Earth-centered, Earth-fixed)
    LLAToECEF(lat, lon, alt = 0) {
    
        var rlat = lat / 180 * Math.PI;
        var rlon = lon / 180 * Math.PI;
    
        var slat = Math.sin(rlat);
        var clat = Math.cos(rlat);
    
        var N = this.wgs84.RADIUS / Math.sqrt(1 - this.wgs84.ECCENTRICITY2() * slat * slat);
    
        var x = (N + alt) * clat * Math.cos(rlon);
        var y = (N + alt) * clat * Math.sin(rlon);
        var z = (N * (1 - this.wgs84.ECCENTRICITY2()) + alt) * slat;
    
        return [x, y, z];
    };
    // convert ECEF (Earth-centered, Earth-fixed) to Longtitute, Latitude, Altitude
    ECEFToLLA(X, Y, Z) {
    
        //Auxiliary values first
        var p = Math.sqrt(X * X + Y * Y);
        var theta = Math.atan((Z * this.wgs84.RADIUS) / (p * this.wgs84.POLAR_RADIUS()));
    
        var sinTheta = Math.sin(theta);
        var cosTheta = Math.cos(theta);
    
        var num = Z + this.wgs84.ECCENTRICITY_PRIME() * this.wgs84.ECCENTRICITY_PRIME() * this.wgs84.POLAR_RADIUS() * sinTheta * sinTheta * sinTheta;
        var denom = p - this.wgs84.ECCENTRICITY() * this.wgs84.ECCENTRICITY() * this.wgs84.RADIUS * cosTheta * cosTheta * cosTheta;
    
        //Now calculate LLA
        var latitude = Math.atan(num / denom);
        var longitude = Math.atan(Y / X);
        var N = this.getN(latitude);
        var altitude = (p / Math.cos(latitude)) - N;
    
        if (X < 0 && Y < 0) {
            longitude = longitude - Math.PI;
        }
    
        if (X < 0 && Y > 0) {
            longitude = longitude + Math.PI;
        }
    
        return [this.degrees(latitude), this.degrees(longitude), altitude];
    };
	latLonToCartesian(lat, lon, height = 0) {
		const a = 6378137.0;       // Semi-major axis
		const b = 6356752.3142;    // Semi-minor axis
		const latRad = MathUtils.degToRad(lat);
		const lonRad = MathUtils.degToRad(lon);

		const cosLat = Math.cos(latRad);
		const sinLat = Math.sin(latRad);
		const cosLon = Math.cos(lonRad);
		const sinLon = Math.sin(lonRad);

		const e2 = 1 - (b * b) / (a * a);  // Square of eccentricity
		const N = a / Math.sqrt(1 - e2 * sinLat * sinLat);  // Prime vertical radius

		const x = (N + height) * cosLat * cosLon;
		const y = (N + height) * cosLat * sinLon;
		const z = ((b * b) / (a * a) * N + height) * sinLat;

		return new Vector3(x, y, z);
	}    
    // convert tms to xyz tiling method
    tmsToXyz(yTms, z) {
		const totalTiles = Math.pow(2, z);
		return (totalTiles - 1) - yTms;
	};
    // convert xyz to tms tiling method
	xyzToTms(yXyz, z) {
		const totalTiles = Math.pow(2, z);
		return (totalTiles - 1) - yXyz;
	};
    // convert lon, lat to tile
    lonLatToTile(lon, lat, zoom) {
		const tileX = Math.floor((lon + 180) / 360 * Math.pow(2, zoom));
		const tileY = Math.floor(
			(1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom)
		);
		return { x: tileX, y: tileY };
	};
    // convert tile to lon, lat
    tileToLonLat(x, y, zoom) {
        const n = Math.PI - 2 * Math.PI * y / Math.pow(2, zoom);
        const lon = x / Math.pow(2, zoom) * 360 - 180;
        const lat = 180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
        return { lon, lat };
    };
    // convert lon, lat to mercator
    customDatumsToSpherical(latitude, longitude, height = 0) {
		const a = UnitsUtils.EARTH_RADIUS_A;  // Semi-major axis (WGS84)
		const b = UnitsUtils.EARTH_RADIUS_B;  // Semi-minor axis (WGS84)
		
		const latRad = MathUtils.degToRad(latitude);
		const lonRad = MathUtils.degToRad(longitude);
	
		const eSquared = (a ** 2 - b ** 2) / a ** 2;  // Eccentricity squared
	
		const N = a / Math.sqrt(1 - eSquared * Math.sin(latRad) ** 2);  // Prime vertical radius
	
		const x = (N + height) * Math.cos(latRad) * Math.cos(lonRad);
		const y = (N + height) * Math.cos(latRad) * Math.sin(lonRad);
		const z = ((1 - eSquared) * N + height) * Math.sin(latRad);
	
		return new Vector3(x, y, z);  // 3D position
	}
}