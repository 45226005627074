import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { Sky } from 'three/addons/objects/Sky.js';
import { MapView, UnitsUtils, MapNodeGeometry, HeightDebugProvider, OpenStreetMapsProvider, GrayscaleHeightmapProvider, PDOKProvider, PDOKBgtProvider } from './heightMapProvider.js';
import Utilities from './utilities.js';

const utils = new Utilities();

import * as dat from 'lil-gui'
import { TilesRenderer } from '../src/three/TilesRenderer.js';

import html2canvas from 'html2canvas';

import {
	ImplicitTilingPlugin,
} from '../src/plugins/base/ImplicitTilingPlugin.js';

import {
	DebugTilesPlugin,
} from '../src/plugins/three/DebugTilesPlugin.js';

import {
	Scene,
	DirectionalLight,
	AmbientLight,
	WebGLRenderer,
	PerspectiveCamera,
	CameraHelper,
	Box3,
	Raycaster,
	Vector2,
	Vector3,
	Mesh,
	CircleGeometry,
	PlaneGeometry,
	CylinderGeometry,
	MeshBasicMaterial,
	LineBasicMaterial,
	Float32BufferAttribute,
	TextureLoader,
	MeshStandardMaterial,
	AdditiveBlending,
	LinearFilter,
	Group,
	Line,
	TorusGeometry,
	RingGeometry,
	BufferGeometry,
	Matrix4,
	MathUtils,
	AxesHelper,
	GridHelper,
	CanvasTexture,
	Fog,
	Quaternion,
	Euler,
	DoubleSide,
	Box3Helper
} from 'three';


// Cesium / 3D tiles Spheroid:
// - Up is Z at 90 degrees latitude
// - 0, 0 latitude, longitude is X axis
//      Z
//      |
//      |
//      .----- Y
//     /
//   X


// js Spherical Coordinates
// - Up is Y at 90 degrees latitude
// - 0, 0 latitude, longitude is Z
//       Y
//      |
//      |
//      .----- X
//     /
//   Z


const tilesetLayers = {
	cities: {
		breda: {
			tilesets: [
				{
					id: 0,
					name: "Breda Boeimeer",
					description: "Breda Boeimeer",
					enabled: true,
					layer_type: "Tileset",
					maximumScreenSpaceError: 4,
					type_description: "explicit | b3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
					crossOrigin: null,
					active: 'checked',
					url: "https://3dtilesnederland.nl/tiles/1.0/implicit/nederland/758.json",
					offset: { x: 0, y: 0, z: 0 }
				},
				{
					id: 1,
					name: "Breda Bomen",
					description: "Breda Bomen",
					enabled: false,
					layer_type: "Tileset",
					maximumScreenSpaceError: 4,
					type_description: "implicit | i3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
					crossOrigin: null,
					active: 'checked',
					url: "https://3dtilesnederland.nl/tiles/1.0/implicit/bomen/breda/tileset.json",
					offset: { x: 0, y: 0, z: 0 }
				},

			],
			offset: { x: 0, y: 0, z: 0 },
			lookAtPositionGradians: { x: 51.58167495823738, y: 4.773743350194697, z: 0 },
			insertsectObjectLayer: 1
		},
		boeimeer: {
			tilesets: [
				{
					id: 0,
					name: "Breda Boeimeer",
					description: "Breda Boeimeer",
					enabled: true,
					layer_type: "Tileset",
					maximumScreenSpaceError: 4,
					type_description: "explicit | b3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
					crossOrigin: null,
					active: 'checked',
					url: "https://3dtilesnederland.nl/tiles/1.0/explicit/boeimeer/7415/tileset.json",
					offset: { x: 0, y: 0, z: 0 }
				},
				{
					id: 1,
					name: "Breda Bomen",
					description: "Breda Bomen",
					enabled: false,
					layer_type: "Tileset",
					maximumScreenSpaceError: 4,
					type_description: "implicit | i3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
					crossOrigin: null,
					active: 'checked',
					url: "https://3dtilesnederland.nl/tiles/1.0/explicit/bomen/breda/abstract/tileset.json",
					offset: { x: 0, y: 0, z: 0 }

				}
			],
			offset: { x: 0, y: 0, z: 0 },
			lookAtPositionGradians: { x: 51.58167495823738, y: 4.773743350194697, z: 0 },
			insertsectObjectLayer: 1
		},
		noordereiland: {
			tilesets: [
				{
					id: 0,
					name: "Noordereiland",
					description: "Noordereiland",
					enabled: true,
					layer_type: "Tileset",
					maximumScreenSpaceError: 4,
					type_description: "explicit | b3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
					crossOrigin: null,
					active: 'checked',
					url: "https://3dtilesnederland.nl/tiles/1.0/explicit/noordereiland/tileset.json",
					offset: { x: 0, y: 0, z: 0 }
				}
			],
			offset: { x: 0, y: 0, z: 0 },
			lookAtPositionGradians: { x: 51.91276305755869, y: 4.493590688301117, z: 0 },
			insertsectObjectLayer: 0
		},
		kopvanzuid: {
			tilesets: [
				{
					id: 0,
					name: "Kop van Zuid",
					description: "Kop van Zuid",
					enabled: true,
					layer_type: "Tileset",
					maximumScreenSpaceError: 4,
					type_description: "explicit | b3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
					crossOrigin: null,
					active: 'checked',
					url: "https://3dtilesnederland.nl/tiles/1.0/explicit/wilhelminakade-textured/tileset.json",
					offset: { x: 0, y: 0, z: 0 }
				}
			],
			offset: { x: 0, y: 26.9, z: 0 },
			lookAtPositionGradians: { x: 51.90583149374232, y: 4.488082262632766, z: 0 },
			insertsectObjectLayer: 0
		},
		valkenburg: {
			tilesets: [
				{
					id: 0,
					name: "Nederland",
					description: "Nederland",
					enabled: true,
					layer_type: "Tileset",
					maximumScreenSpaceError: 4,
					type_description: "explicit | b3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
					crossOrigin: null,
					active: 'checked',
					url: "https://3dtilesnederland.nl/tiles/1.0/implicit/nederland/v20240420/tileset.json",
					offset: { x: 0, y: 0, z: 0 }
				},
				{
				   id: 2,
				    name: "Valkenburg Bomen",
				    description: "Valkenburg Bomen",
				    enabled: true,
				    layer_type: "Tileset",
				    maximumScreenSpaceError: 4,
				    type_description: "implicit | i3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
				    crossOrigin: null,
				    active: 'checked',
				    url: "https://3dtilesnederland.nl/tiles/1.0/implicit/bomen/valkenburg/tileset.json",
				    offset: { x: 0, y: 0, z: 0 }
				 }					
			],
			offset: { x: 0, y: 26.9, z: 0 },
			lookAtPositionGradians: { x: 50.86038278448532, y: 5.824303243154913, z: 0 },   
			insertsectObjectLayer: 0
		},
		nederland: {
			tilesets: [
				{
					id: 0,
					name: "Nederland",
					description: "Nederland",
					enabled: true,
					layer_type: "Tileset",
					maximumScreenSpaceError: 4,
					type_description: "explicit | b3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
					crossOrigin: null,
					active: 'checked',
					url: "https://3dtilesnederland.nl/tiles/1.0/implicit/nederland/v20240420/tileset.json",
					offset: { x: 0, y: 0, z: 0 }
				},
				{
					id: 1,
					name: "Breda Bomen",
					description: "Breda Bomen",
					enabled: false,
					layer_type: "Tileset",
					maximumScreenSpaceError: 4,
					type_description: "implicit | i3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
					crossOrigin: null,
					active: 'checked',
					url: "https://3dtilesnederland.nl/tiles/1.0/implicit/bomen/breda/tileset.json",
					offset: { x: 0, y: 0, z: 0 }
				}
				,
				{
				   id: 2,
				    name: "Valkenburg Bomen",
				    description: "Valkenburg Bomen",
				    enabled: true,
				    layer_type: "Tileset",
				    maximumScreenSpaceError: 4,
				    type_description: "implicit | i3dm | 1.0 | pg2b3dm | Amersfoort / RD New + NAP height",
				    crossOrigin: null,
				    active: 'checked',
				    url: "https://3dtilesnederland.nl/tiles/1.0/implicit/bomen/valkenburg/tileset.json",
				    offset: { x: 0, y: 0, z: 0 }
				 }					
				 ,
			],
			offset: { x: 0, y: 0, z: 0 },
			lookAtPositionGradians: { x: 51.82573333588821, y: 4.639693103276338, z: 0 },
			insertsectObjectLayer: 0
		}
	}
};

const NONE = 0;
const ALL_HITS = 1;
const FIRST_HIT_ONLY = 2;

const orbitControlsEnabled = true;
const debugPositions = false;
const fogEnabled = true;

// gui controls
let scaleControlValue = 1.6;
let offsetPositionX = 0, offsetPositionY = 0, offsetPositionZ = 0;

let controllerLineStartX = 0, controllerLineStartY = 0, controllerLineStartZ = 0;
let controllerLineEndX = 0, controllerLineEndY = 0, controllerLineEndZ = 0;

let mapMode;
let activeCity;
let maxZoom;
let baseMap;

let planeData = {
	width: 256,
	height: 256,
	widthSegments: 8,
	heightSegments: 8
};

// customMap
let baseLayerProvider;
let heightMapProvider;

let font;
let textMesh;
let infoMesh;

// messageboard
let mbContext;
let mbTexture;
let mbCanvas;
let mbPlane

let osmParent1
let OSM
let offsetParent
let geospatialRotationParent
let osmOffsetParent
let heightParent = []
let geospatialRotationParents = [];
let box;

let camera, orbitControls, scene, renderer, cameraHelper;
let secondRenderer;
let raycaster, mouse, rayIntersect, lastHoveredElement, pointer;
let openStreetMap, osmCoords, osmECEFcoordinates, osmECEFCartesian;
let activeCityLookatPosition;
let emptyPlaneMesh;
let mapView;
let baseReferenceSpace, offsetReferenceSpace;
let marker;
let controller0, controller1;
let controllerGrip1, controllerGrip2;

let intersection;
let intersectionobject
let intersectionobjecttype;

let exitButton;

const tempMatrix = new Matrix4();

const startPos = new Vector2();
const endPos = new Vector2();
let tilesRenderers = [];

var osmMaterials = [];
var osmTiles = [];


const params = {

	/* 3D tiles */
	enableUpdate: true,
	raycast: FIRST_HIT_ONLY,
	optimizeRaycast: true,
	enableCacheDisplay: false,
	enableRendererStats: false,
	orthographic: false,

	errorTarget: 12,
	errorThreshold: 60,
	maxDepth: 15,
	loadSiblings: true,
	stopAtEmptyTiles: true,
	displayActiveTiles: false,
	resolutionScale: 1.0,

	up: '+Z',//,hashUrl ? '+Z' : '+Y',
	displayBoxBounds: false,
	displaySphereBounds: false,
	displayRegionBounds: false,
	colorMode: 0,
	showThirdPerson: false,
	showSecondView: false,
	reload: reinstantiateTiles,

};

/************************************************************************
*
* handling debugging
*
*************************************************************************/

let originalLog = console.log; // Save the original console.log

// Function to turn off console.log
window.disableConsoleLog = function () {
	console.log = function () { }; // Replace with a no-op function
};

// Function to restore console.log
window.enableConsoleLog = function () {
	console.log = originalLog; // Restore original console.log
};

/************************************************************************
*
* handling lurl parameters
*
*************************************************************************/

// Get the full URL
const fullUrl = window.location.href;

// Create a URL object
const url = new URL(fullUrl);

// get url values
mapMode = url.searchParams.get('mapMode');
maxZoom = url.searchParams.get('maxZoom');
activeCity = url.searchParams.get('city');
baseMap = url.searchParams.get('baseMap');

console.log ('baseMap: ', baseMap);
console.log ('mapMode: ', mapMode);
console.log ('activeCity: ', activeCity);
console.log ('maxZoom: ', maxZoom);

if (!baseMap) {
	console.log ('baseMap not found');
	url.searchParams.set('baseMap', 'openStreetMap');
	window.location.href = url.toString();
}

if (!mapMode) {
	url.searchParams.set('mapMode', 'customMap');
	window.location.href = url.toString();
}

if (!maxZoom) {
	url.searchParams.set('maxZoom', '18');
	window.location.href = url.toString();
}

if (!activeCity) {
	console.log('No URL found, default city is used');
	url.searchParams.set('city', 'breda');
	window.location.href = url.toString();
} 


disableConsoleLog();

//enableConsoleLog();

init()
render()
animate()
renderer.render(scene, camera);



/************************************************************************
*
* handling listening functions
*
*************************************************************************/

function listenEvent(eventObj, event, eventHandler) {
	if (eventObj.addEventListener) {
		eventObj.addEventListener(event, eventHandler, false);
	}
	else if (eventObj.attachEvent) {
		event = "on" + event;
		eventObj.attachEvent(event, eventHandler);
	}
	else {
		eventObj["on" + event] = eventHandler;
	}
}

function stopListening(eventObj, event, eventHandler) {
	if (eventObj.removeEventListener) {
		eventObj.removeEventListener(event, eventHandler, false);
	}
	else if (eventObj.detachEvent) {
		event = "on" + event;
		eventObj.detachEvent(event, eventHandler);
	}
	else {
		eventObj["on" + event] = null;
	}
}


function buildController(data) {

	console.log('buildController (+)');

	let geometry, material;

	camera.position.set(0, 300, 0);

	switch (data.targetRayMode) {

		case 'tracked-pointer':

			console.log('controller0.matrixWorld');

			geometry = new BufferGeometry();
			geometry.setAttribute('position', new Float32BufferAttribute([0, 0, 0, 0, 0, -2], 3));
			geometry.setAttribute('color', new Float32BufferAttribute([0.5, 0.5, 0.5, 0, 0, 0], 3));

			material = new LineBasicMaterial({ vertexColors: true, blending: AdditiveBlending });

			return new Line(geometry, material);

		case 'gaze':

			geometry = new RingGeometry(0.02, 0.04, 32).translate(0, 0, - 1);

			material = new MeshBasicMaterial({
				color: 0x0000ff, // Blue color
				opacity: 0.8,
				transparent: true,
			});

			return new Mesh(geometry, material);

	}

	console.log('buildController (-)');

}

function onExitVR() {
    const session = renderer.xr.getSession();
    if (session) {
        session.end(); // Beëindig de VR-sessie
        console.log('VR-sessie beëindigd via Exit VR-knop.');
    }
}


async function onSelectEnd() {

	console.log('onSelectEnd (+)');

	console.log ('intersection', intersection);
	console.log ('intersectionobjecttype: ', intersectionobjecttype);
	console.log ('intersectionobject', intersectionobject);

	// when intersects with exit button
	const intersects = raycaster.intersectObject(exitButton, true);
	if (intersects.length > 0) {
		onExitVR();
	}

	if (intersectionobjecttype === '3DOBJECT') {
		// due to this clause we also create a physical boundary	
		
		const results = raycaster.intersectObject(tilesRenderers[0].group, true);

		const feature = getFeatureIdentifier(results);
		console.log ('feature: ', feature);

		const bagInfo = await getBagInfo (feature.identificatie);
		updateText( bagInfo);

		rayIntersect.visible = false;
		return;
	}

    // when intersects with basemap
	if (intersection) {

		const offsetPosition = { x: - intersection.x, y: - intersection.y - 16, z: - intersection.z, w: 1 };
		const offsetRotation = new Quaternion();
		const transform = new XRRigidTransform(offsetPosition, offsetRotation);
		const teleportSpaceOffset = baseReferenceSpace.getOffsetReferenceSpace(transform);

		renderer.xr.setReferenceSpace(teleportSpaceOffset);

	}

	console.log('onSelectEnd (-)');
} 

function onSelectStartLeft() {

	console.log('onSelectStartLeft (+)');

	this.userData.isSelecting = true;

	console.log('onSelectStartLeft (-)');

}

function onSelectEndLeft() {

	console.log('onSelectEndLeft (+)');

	onSelectEnd();

	this.userData.isSelecting = false;

	console.log('onSelectEndLeft (-)');

}

function onSelectStartRight() {

	console.log('onSelectStartRight (+)');

	this.userData.isSelecting = true;

	console.log('onSelectStartRight (-)');

}

function onSelectEndRight() {

	console.log('onSelectEndRight (+)');

	onSelectEnd();

	this.userData.isSelecting = false;

	console.log('onSelectEndRight (-)');

}

function addExitButton() {

	console.log('addExitButton (+)');

	// Create an "Exit VR" button
	const exitButtonGeometry = new PlaneGeometry(36, 16); // button size
	const exitButtonMaterial = new MeshStandardMaterial({ color: 0xFF0000 }); // red
	exitButton = new Mesh(exitButtonGeometry, exitButtonMaterial);

    const textGeometry = new TextGeometry('EXIT VR', {
        font: font,
        size: 5,
        height: 1,
		curveSegments:  8,
        bevelEnabled: false
    });
    
    const textMaterial = new MeshStandardMaterial({ 
        color: 0xffffff
    });
    
    textMesh = new Mesh(textGeometry, textMaterial);
    textMesh.castShadow = false;
    textMesh.receiveShadow = false;
    textMesh.position.set(-12, -3, -0.5); // Text in front of the button (z-fighting)
    
    exitButton.add(textMesh);

	exitButton.position.set(-70, 45, -140); // in front on the user

	console.log('addExitButton (-)');
}

function updateText(newText) {

	console.log('updateText (+)');

    // create text mesh
    if (infoMesh) {
        if (infoMesh.parent) {
            infoMesh.parent.remove(infoMesh);
        }
        infoMesh.geometry.dispose();
        if (infoMesh.material) {
            infoMesh.material.dispose();
        }
    }
    
    const textGeometry = new TextGeometry(newText, {
        font: font,
        size: 4,
        height: 0.01,
		curveSegments:  8,
        bevelEnabled: false
    });
    
    const textMaterial = new MeshStandardMaterial({ 
        color: 0x000000 
    });

    infoMesh = new Mesh(textGeometry, textMaterial);
    infoMesh.castShadow = false;
    infoMesh.receiveShadow = false;

    infoMesh.position.set(-40, 45, -134); // in front on the user

	camera.add(infoMesh);

	console.log('updateText (+)');

}

async function init() {

	console.log('build address container');
	let html = '';

	// load font once
	const loader = new FontLoader();
	loader.load('./fonts/helvetiker_regular.typeface.json', (loadedFont) => {	
		font = loadedFont;
	});
		
	// add search address section
	if (!document.getElementById("searchContent")) {
		const addressContainer = document.createElement("div");
		addressContainer.id = 'searchContent';
		addressContainer.className = 'searchContent';

		html += '<div id="addressContainer">';
		html += '<h2 style="text-align: center;"><span class="sidebarTitle">Adres zoeken</span></h2>';
		html += '<input type="search" id="adres" class="addresSearch" placeholder="Nederlands adres..." name="Adres"></div>';

		addressContainer.innerHTML = html;
		document.body.appendChild(addressContainer);
	}

	if (!document.getElementById("searchContent")) {
		const searchContent = document.createElement("div");
		searchContent.id = 'searchContent';
		searchContent.className = 'sidebarContent';
		searchContent.innerHTML = html;
		addressContainer.appendChild(searchContent);
	}

	if (!document.getElementById("resultsContainer")) {
		const zoekResultaten = document.createElement("div");
		zoekResultaten.innerHTML = '<span class="zpText">Geen zoekresultaten</span></div>'
		zoekResultaten.id = 'resultsContainer';
		addressContainer.appendChild(zoekResultaten);
	}

	var addressBox = document.getElementById("adres");
	listenEvent(addressBox, "keyup", refreshAdressList);

	//listenEvent(document.getElementById("mapSelect"), "change", function() {
	//	mapSelect(this); // Hier pas de functie aanroepen
	//});
	

	// create new 3D scene
	scene = new Scene();

	if (fogEnabled) {
		const color = 0xcccccc; // color oth the fog
		const near = 5000; // from visibility
		const far = 8000; // to visibility
		scene.fog = new Fog(color, near, far);
	}
	
	const axesHelper = new AxesHelper(5); // 5 is the length of the axes
	//scene.add(axesHelper);

	const size = 10;
	const divisions = 10;
	const gridHelper = new GridHelper(size, divisions);
	//scene.add( gridHelper )

	// new renderer
	renderer = new WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))	
	renderer.setSize(window.innerWidth, window.innerHeight);
	renderer.setClearColor(0x151c1f);

	// primary camera view
	camera = new PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 10000); //new camera incl. near and far
	camera.position.set(150, 1000, 600);
	camera.lookAt(1000, 1000, 1000);

	scene.add(camera);
	cameraHelper = new CameraHelper(camera);
	//scene.add(cameraHelper);

	let heightOffset = 260  // 10 meters height offset
	renderer.xr.addEventListener('sessionstart', (event) => {

		renderer.xr.setReferenceSpaceType('local-floor');

		// lower pixel ratio for VR
		const isStandaloneVR = /OculusBrowser|Quest|Pico/.test(navigator.userAgent);
		renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

		//console.log ('heightOffset: ' + heightOffset)
		baseReferenceSpace = renderer.xr.getReferenceSpace();

		// Create an offset in the Y direction (height)
		const offsetPosition = { x: 0, y: -heightOffset, z: 0 };
		const offsetRotation = new Quaternion();  // No rotation adjustment
		const transform = new XRRigidTransform(offsetPosition, offsetRotation);

		// Apply the offset reference space
		offsetReferenceSpace = baseReferenceSpace.getOffsetReferenceSpace(transform);
		renderer.xr.setReferenceSpace(offsetReferenceSpace);

		console.log('baseReferenceSpace', baseReferenceSpace);
		console.log('offsetReferenceSpace', offsetReferenceSpace);

		addExitButton('Exit VR');
		updateText ('Standalone VR Indicator: ' + isStandaloneVR + '\nwindow.devicePixelRatio: ' + window.devicePixelRatio.toFixed(1));

		camera.add(exitButton);
		
	});

	renderer.xr.addEventListener('sessionend', () => {
		const session = renderer.xr.getSession();
		if (session) {
			session.end(); // Beëindig de VR-sessie
			console.log('VR-sessie beëindigd via Exit VR-knop.');
		}
	});

	document.addEventListener("visibilitychange", () => {
		if (document.hidden && renderer.xr.getSession()) {
			renderer.xr.getSession().end();
			console.log("VR sessie beëindigd omdat gebruiker de tab verliet.");
		}
	});

	renderer.xr.enabled = true;
	document.body.appendChild(VRButton.createButton(renderer));

	// red marker for raycasting on the ground
	marker = new Mesh(
		new CircleGeometry(0.25, 32).rotateX(- Math.PI / 2),
		new MeshBasicMaterial({ color: 'red' }) 
	);
	marker.scale.set(5, 5, 5);
	scene.add(marker);

	document.body.appendChild(renderer.domElement);

	secondRenderer = new WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
	secondRenderer.setPixelRatio(window.devicePixelRatio);
	secondRenderer.setSize(window.innerWidth, window.innerHeight);
	secondRenderer.setClearColor(0x151c1f);

	document.body.appendChild(secondRenderer.domElement);
	secondRenderer.domElement.style.position = 'absolute';
	secondRenderer.domElement.style.right = '0';
	secondRenderer.domElement.style.top = '0';
	secondRenderer.domElement.style.outline = '#0f1416 solid 2px';
	secondRenderer.domElement.tabIndex = 1;

	//controllers
	console.log('Add controller 0 (right)');
	controller0 = renderer.xr.getController(0);
	controller0.addEventListener('selectstart', onSelectStartRight);
	controller0.addEventListener('selectend', onSelectEndRight);
	controller0.addEventListener('connected', function (event) {

		const handedness = event.data.handedness; // "left" or "right"
		console.log('Controller 0 is:', handedness);
		this.controllerActive = true;
		this.add(buildController(event.data));

	});
	controller0.addEventListener('disconnected', function () {

		this.controllerActive = false;
		this.remove(this.children[0]);

	});

	console.log('Add controller 1 (left)');
	controller1 = renderer.xr.getController(1);
	controller1.addEventListener('selectstart', onSelectStartLeft);
	controller1.addEventListener('selectend', onSelectEndLeft);
	controller1.addEventListener('connected', function (event) {

    const handedness = event.data.handedness; // "left" or "right"
    console.log('Controller 1 is:', handedness);
		this.controllerActive = true;
		this.add(buildController(event.data));

	});
	controller1.addEventListener('disconnected', function () {

		this.controllerActive = false;
		this.remove(this.children[0]);

	});

	// The XRControllerModelFactory will automatically fetch controller models
	// that match what the user is holding as closely as possible. The models
	// should be attached to the object returned from getControllerGrip in
	// order to match the orientation of the held device.

	const controllerModelFactory = new XRControllerModelFactory();

	controllerGrip1 = renderer.xr.getControllerGrip(0);
	controllerGrip1.add(controllerModelFactory.createControllerModel(controllerGrip1));
	scene.add(controllerGrip1);

	controllerGrip2 = renderer.xr.getControllerGrip(1);
	controllerGrip2.add(controllerModelFactory.createControllerModel(controllerGrip2));
	scene.add(controllerGrip2);

	let controllerCamera = (renderer.xr.getCamera());
	console.log('controllerCamera: ', controllerCamera);

	controller0.position.set(
		camera.position.x + 3.2 + controllerLineStartX, // 0.2,   // Offset the controller 0.2 meters to the right of the headset
		camera.position.y - 4.8 + controllerLineStartY, //0.3,   // Offset the controller 0.3 meters down relative to the headset
		camera.position.z - 8 + controllerLineStartZ    // Offset the controller 0.5 meters in front of the headset
	);

	// Add the controller to the scene
	scene.add(controller0);

	controller1.position.set(
		camera.position.x + 3.2 + controllerLineEndX,   // Offset the controller 0.2 meters to the right of the headset
		camera.position.y - 4.8 + controllerLineEndY,   // Offset the controller 0.3 meters down relative to the headset
		camera.position.z - 8 + controllerLineEndZ   // Offset the controller 0.5 meters in front of the headset
	);

	// Add the controller to the scene
	scene.add(controller1);

	// Raycasting init
	raycaster = new Raycaster();
	mouse = new Vector2();
	pointer = new Vector2();

	rayIntersect = new Group();
	rayIntersect.name = 'rayIntersect';

	const rayIntersectMat = new MeshBasicMaterial({ color: 0xe91e63 });
	const rayMesh = new Mesh(new CylinderGeometry(0.25, 0.25, 6), rayIntersectMat);
	rayMesh.rotation.x = Math.PI / 2;
	rayMesh.position.z += 3;
	rayIntersect.add(rayMesh);

	const rayRing = new Mesh(new TorusGeometry(1.5, 0.2, 16, 100), rayIntersectMat);
	rayIntersect.add(rayRing);
	scene.add(rayIntersect);
	rayIntersect.visible = false;

	if (orbitControlsEnabled) {

		orbitControls = new OrbitControls(camera, renderer.domElement)

		orbitControls.enableDamping = true
		orbitControls.enablePan = true
		orbitControls.zoomSpeed = 8
		orbitControls.enableZoom = true;
		orbitControls.minZoom = 0.0;
		orbitControls.maxZoom = 1000.0;

	}

	// lights
	const dirLight = new DirectionalLight(0xffffff, 3);
	dirLight.position.set(100, 200, 100);
	scene.add(dirLight);

	const ambLight = new AmbientLight(0xffffff, 0.2);
	scene.add(ambLight);

	box = new Box3();

	// parent group
	offsetParent = new Group();
	offsetParent.name = 'offsetParent';
	scene.add(offsetParent);
	console.log('offsetParent: ', offsetParent);

	// child groups containing tiles 
    geospatialRotationParent = new Group();
	geospatialRotationParent.name = 'geospatialRotationParent_';
	offsetParent.add(geospatialRotationParent);

	// active city and set lookAt position
	console.log('Active city: ', tilesetLayers.cities[activeCity]);

	let currentUrl = new URL(window.location.href);
	let searchCoordinates = currentUrl.searchParams.get('searchCoordinates');

	if (searchCoordinates) {
		let values = searchCoordinates.replace("POINT(", "").replace(")", "");
		let [firstValue, lastValue] = values.split(" ");

		activeCityLookatPosition = {
			x: parseFloat(lastValue),
			y: parseFloat(firstValue),
			z: 0
		};

	} else {
		activeCityLookatPosition = tilesetLayers.cities[activeCity].lookAtPositionGradians;
	}
	console.log('activeCityLookatPosition: ', activeCityLookatPosition);

	// add open street map
	if (mapMode === 'mapView') {

		console.log('Add open street map');

		const provider = new OpenStreetMapsProvider();
		provider.minZoom = 1;
		provider.maxZoom = 19;

		openStreetMap = new MapView(MapView.PLANAR, provider, 0.1, 150);
		openStreetMap.name = 'openStreetMap';

		// sync open street map with tiles	
		osmCoords = { ...UnitsUtils.datumsToSpherical(activeCityLookatPosition.x, activeCityLookatPosition.y, activeCityLookatPosition.z) };
		console.log('osmCoords: ', osmCoords);
		openStreetMap.position.set(-osmCoords.x, 0, osmCoords.y);
		openStreetMap.updateMatrixWorld(true);
		scene.add(openStreetMap)

	}

	// convert the lookAt position to ECEF
	osmECEFcoordinates = utils.LLAToECEF(activeCityLookatPosition.x, activeCityLookatPosition.y, activeCityLookatPosition.z);
	console.log('osmECEFcoordinates: ', osmECEFcoordinates);

	// alternative function (not in use)
	osmECEFCartesian = (utils.latLonToCartesian ( activeCityLookatPosition.x, activeCityLookatPosition.y, activeCityLookatPosition.z));
	console.log('osmECEFCartesian: ', osmECEFCartesian);
	
	if (mapMode === 'customMap') {
		console.log('Add open street map');

		// Get the full URL
		const fullUrl = window.location.href;
		const url = new URL(fullUrl);
		baseMap = url.searchParams.get('baseMap');

		if (baseMap === 'openStreetMap') {
			baseLayerProvider = new OpenStreetMapsProvider();
		} else if (baseMap === 'BGT') {
			baseLayerProvider = new PDOKBgtProvider();
		} else {
			baseLayerProvider = new PDOKProvider();
		};

		baseLayerProvider.minZoom = 1;
		baseLayerProvider.maxZoom = maxZoom;
		
		heightMapProvider = new GrayscaleHeightmapProvider();

		heightMapProvider.minZoom = 1;
		heightMapProvider.maxZoom = maxZoom;

		// Create the MapView
		mapView = new MapView(MapView.HEIGHT, baseLayerProvider, heightMapProvider);
		mapView.name = 'mapView';

		osmCoords = { ...UnitsUtils.datumsToSpherical(activeCityLookatPosition.x, activeCityLookatPosition.y, activeCityLookatPosition.z) };
		console.log('osmCoords: ', osmCoords);
		mapView.position.set(-osmCoords.x, 0, osmCoords.y);
		mapView.updateMatrixWorld(true);

		scene.add(mapView);

		console.log ('mapView: ', mapView);

	}

	function getDisplacementScaleAndBias(min, max) {

		const displacementScale = (max - min)  * scaleControlValue;
		const displacementBias =  (max + min) / 2 / scaleControlValue;

		return { displacementScale, displacementBias };
	}

	if (mapMode === 'heightMap') {

		const range = 3;
		const zoom = 18;
		const tileSize = 256 // Size of each tile
		let minTileX = 9999999;
		let maxTileX = 0;

		console.log('activeCityLookatPosition.x, activeCityLookatPosition.y', activeCityLookatPosition.x, activeCityLookatPosition.y);
		const centerLon = activeCityLookatPosition.y;
		const centerLat = activeCityLookatPosition.x;

		const { x: centerX, y: centerY } = utils.lonLatToTile(centerLon, centerLat, zoom);
		console.log('centerX, centerY', centerX, centerY);
		offsetParent.rotation.set(0, 0, Math.PI / 1);
		offsetParent.rotation.x = - Math.PI / 2;

		osmParent1 = new Group();
		osmParent1.name = 'osmParent1';
		osmParent1.rotation.set(-Math.PI / 2, Math.PI , Math.PI);

		// Create a parent mesh (e.g., a cube)
		const OSMgeometry = new PlaneGeometry(2048, 2048, 256, 256);
		const OSMMaterial = new MeshBasicMaterial({ color: 0x0000ff , // greblueen
													transparent: true,  // Enable transparency
													opacity: 0.1
												  }
												   );
		OSMMaterial.frustumCulled = false;
		OSM = new Mesh(OSMgeometry, OSMMaterial);
		OSMMaterial.receiveShadow = true;
		OSM = new Mesh(OSMgeometry, OSMMaterial);
		OSM.position.set(0, 0, 1);
		OSM.name = 'OSM';
		OSM.rotation.set(-Math.PI / 2, 0, 0);
		OSMMaterial.needsUpdate = true;
		scene.add(OSM);

		OSM.add(osmParent1)		
	
		// statistics minumum and maximum values from GDAL information
		const displacementValues = getDisplacementScaleAndBias(60, 150);
		console.log('displacementValues: ', displacementValues);

		for (let dx = -range; dx <= range; dx++) {
			for (let dy = -range; dy <= range; dy++) {

				//console.log('dx, dy', dx, dy);

				const tileX = centerX + dx;
				const tileY = centerY + dy;

				if (tileX < minTileX) {
					minTileX = tileX;
				}
				if (tileX > maxTileX) {
					maxTileX = tileX;
				}

				//const tileUrl =  `https://tile.openstreetmap.org/${zoom}/${tileX}/${tileY}.png`;
				const tileUrl = `https://a.tile.openstreetmap.org/${zoom}/${tileX}/${tileY}.png`;
				//console.log ('tileUrl: ', tileUrl);	

				//const tileUrl = `https://3dtilesnederland.nl/tiles/terrain/hmtiles/osm/${zoom}/${tileX}/${tileY}.png`;
				//const tileUrl =  `https://tile.openstreetmap.org/0/0/0.png`;
				//console.log('tileUrl: ', tileUrl);
				const terrainUrl = `https://3dtilesnederland.nl/tiles/terrain/hmtiles/files/xyztiles/${zoom}/${tileX}/${tileY}.png`;

				//const terrainUrl = `http://192.168.178.228/terrain/nederland/xyztiles/${zoom}/${tileX}/${tileY}.png`;
				//const terrainUrl = `http://192.168.178.228/terrain/combi/${zoom}/${tileX}/${tileY}.png`;

				Promise.all([loadTile(tileUrl), loadTexture(terrainUrl)])
				.then(([osmTexture, heightMap]) => {

					    //console.log('heightMap: ', heightMap);

						const maxAnisotropy = renderer.capabilities.getMaxAnisotropy();
						osmTexture.anisotropy = maxAnisotropy;
						osmTexture.magFilter = LinearFilter;
						osmTexture.minFilter = LinearFilter;
						osmTexture.generateMipmaps = false;
						osmTexture.needsUpdate = true;
						
						const mapGeometry = new PlaneGeometry(tileSize, tileSize, 32, 32);
												
						const material = new MeshStandardMaterial({
							map: osmTexture,
							displacementMap: heightMap,
							displacementScale:  displacementValues.displacementScale, // Adjust exaggeration
							displacementBias: displacementValues.displacementBias, //displacementBias, //0,  // Optional: offsets the height
							wireframe: false,      // Set true to debug geometry
							side: DoubleSide,
						});

						//mapGeometry.baseScale = new Vector3(UnitsUtils.EARTH_PERIMETER, 1.0, UnitsUtils.EARTH_PERIMETER);

						const tile = new Mesh(mapGeometry, material);
						tile.frustumCulled = false; // Avoid getting clipped


						// Position the tile relative to (0, 0, 0)
						//console.log('tileX, centerX, tileSize', tileX, centerX, tileSize); 
						const offsetX = (tileX - centerX) * tileSize;
						const offsetY = (tileY - centerY) * tileSize;
						//console.log('offsetX, offsetY', offsetX, offsetY);
						//tile.position.set(offsetX + (64 / scaleControlValue), 0,  offsetY - (64 / scaleControlValue)); // Relative positioning
						tile.position.set(offsetX  , 0,  offsetY ); // Relative positioning

		
						//tile.rotation.x = -Math.PI * 0.5; // Rotate to make tiles horizontal
						tile.rotation.set(-Math.PI / 2, 0, 0);						

						osmMaterials.push(material);
						osmTiles.push(tile);
						heightParent.push(heightMap);
						osmParent1.add(tile);
						//scene.add(tile);
						;

						// Use your material and geometry here to create the mesh
						//console.log('Material and textures loaded successfully:', material);
					})
					.catch((error) => {
						console.error('An error occurred while loading textures:', error);
					});
			}

		}		
		console.log('minTileX: ', minTileX);
		console.log('maxTileX: ', maxTileX);
	}
		
	if (mapMode === 'heightMapV2') {

		console.log('Add openstreetmap with heightmap');

		osmParent1 = new Group();
		osmParent1.name = 'osmParent';
		osmParent1.position.set(-osmECEFcoordinates[0], -osmECEFcoordinates[1], osmECEFcoordinates[2]);

		osmOffsetParent = new Group();
		osmOffsetParent.name = 'osmOffsetParent';
		osmOffsetParent.add(osmParent1);
		osmOffsetParent.position.set(0, 0, 0);

		osmOffsetParent.matrix.decompose(osmParent1.position, osmParent1.quaternion, osmParent1.scale);
		osmOffsetParent.quaternion.invert();
		osmOffsetParent.scale.set(1.3, 1.3, 1.3);
		osmOffsetParent.updateMatrix(true);
		osmOffsetParent.updateMatrixWorld(true);
		osmOffsetParent.position.multiplyScalar(1);
		osmOffsetParent.needsUpdate = true;

		scene.add(osmOffsetParent);
		
		console.log('activeCityLookatPosition.x, activeCityLookatPosition.y', activeCityLookatPosition.x, activeCityLookatPosition.y);
		const centerLon = activeCityLookatPosition.y;
		const centerLat = activeCityLookatPosition.x;

		// Parameters
		const zoom = 18; // Zoom level
		const { x: centerTileX, y: centerTileY } = utils.lonLatToTile(centerLon, centerLat, zoom);
		console.log ('centerTileX, centerTileY: ', centerTileX, centerTileY);

		const tileSize = 256; // Size of each tile in units
		const range = 8; // Range of tiles to load (-range to +range)

		// Storage for tiles
		const tiles = {};

		// Generate URLs for OSM and heightmap tiles
		function generateTileUrls(range, centerX, centerY, zoom) {
			const osmUrls = [];
			const heightmapUrls = [];

			for (let dx = -range; dx <= range; dx++) {
				for (let dy = -range; dy <= range; dy++) {
					const tileX = centerX + dx;
					const tileY = centerY + dy;

					const osmUrl = `https://a.tile.openstreetmap.org/${zoom}/${tileX}/${tileY}.png`;
					const heightmapUrl = `https://3dtilesnederland.nl/tiles/terrain/hmtiles/files/xyztiles/${zoom}/${tileX}/${tileY}.png`;

					osmUrls.push({ x: tileX, y: tileY, zoom, url: osmUrl });
					heightmapUrls.push({ x: tileX, y: tileY, zoom, url: heightmapUrl });
				}
			}

			return { osmUrls, heightmapUrls };
		}

		// Load all textures
		function loadTextures(tileUrls, type) {
			const textureLoader = new TextureLoader();
			return Promise.all(
				tileUrls.map(({ x, y, zoom, url }) => {
					return new Promise((resolve, reject) => {
						//console.log(`Loading ${type} texture from: `, url);
						textureLoader.load(
							url,
							(texture) => {
								const key = `${zoom}_${x}_${y}`;
								if (!tiles[key]) tiles[key] = {};
								tiles[key][type] = texture;  // Voorkom overschrijven
								resolve();
							},
							undefined,
							(err) => reject(err)
						);
					});
				})
			);
		}
		

		// Create displacement tiles
		function createTiles(scene, centerX, centerY, tileSize) {

			console.log ('createTiles OSM tiles with height (+)');

			Object.keys(tiles).forEach((key) => {

				//console.log ('key: ', key);
				//console.log ('tiles[key]: ', tiles[key]);

				const { texture, heightmap } = tiles[key];
				if (!texture || !heightmap) return;

				texture.generateMipmaps = false;
				//texture.format = RGBAFormat;
				texture.magFilter = LinearFilter;
				texture.minFilter = LinearFilter;
				texture.needsUpdate = true;
				
				//const geometry = new PlaneGeometry(tileSize, tileSize, 32, 32);
				const geometry = new MapNodeGeometry(256, 256, 32, 32, true);

				//console.log ('heightmap: ', heightmap)	
				const material = new MeshStandardMaterial({
					map: texture,
					displacementMap: heightmap,
					displacementScale: 110, // Adjust height exaggeration
					displacementBias: 110,  // Optional: offsets the height
					//side: DoubleSide,
				});

				const tile = new Mesh(geometry, material);
				tile.frustumCulled = false; // Avoid getting clipped

				// Extract tile coordinates from the key
				const [zoom, x, y] = key.split('_').map(Number);
				const offsetX = (x - centerX) * tileSize;
				const offsetY = (y - centerY) * tileSize;
				//console.log('zoom, offsetX, offsetY: ', zoom, offsetX, offsetY);

				tile.position.set(offsetX, 0, offsetY);
				//tile.rotation.x = -Math.PI * 0.5;

				//tile.baseGeometry = tile.geometry;
				//tile.baseScale = new Vector3(UnitsUtils.EARTH_PERIMETER, 1.0, UnitsUtils.EARTH_PERIMETER);
	
				osmMaterials.push(material);
				osmTiles.push(tile);

				osmParent1.add(tile);
			});

			console.log ('createTiles OSM tiles with height (-)');

		}

		// Generate tile URLs
		const { osmUrls, heightmapUrls } = await generateTileUrls(range, centerTileX, centerTileY, zoom);
		console.log('osmUrls: ', osmUrls);	
		console.log('heightmapUrls: ', heightmapUrls);

		Promise.all([
			loadTextures(osmUrls, 'texture'),
			loadTextures(heightmapUrls, 'heightmap')
		])
		.then(() => {
			console.log('All textures loaded!');
			createTiles(scene, centerTileX, centerTileY, tileSize);
		})
		.catch((error) => {
			console.error('Error loading textures:', error);
		});

	}

	// init control helpers
	initGui();

	// load tiles
	await reinstantiateTiles();

	// load sky
	const sky = new Sky();
	sky.scale.setScalar(450000);

	const phi = MathUtils.degToRad(90);
	const theta = MathUtils.degToRad(180);
	const sunPosition = new Vector3().setFromSphericalCoords(1, phi, theta);

	sky.material.uniforms.sunPosition.value = sunPosition;
	sky.name = 'sky';
	scene.add(sky);

	const messageBoardDiv = document.getElementById('messageBoardContainer');
	messageBoardDiv.innerHTML = 'Hallo, welkom in ' + activeCity; // Set the text of the hidden div

	// HTML to Canvas
	mbCanvas = document.createElement('canvas');
	mbCanvas.width = 1024;  // Set resolution
	mbCanvas.height = 512;

	mbContext = mbCanvas.getContext('2d');
	renderHTMLToCanvas();

	// Texture from Canvas
	mbTexture = new CanvasTexture(mbCanvas);
	const mbGeometry = new PlaneGeometry(20, 10); // Size of the message board
	const mbMaterial = new MeshBasicMaterial({ color: 0xffffff, map: mbTexture, side: DoubleSide });

	mbPlane = new Mesh(mbGeometry, mbMaterial);
	mbPlane.position.set(1, 100, 1); // Place in front of the user
	mbPlane.name = 'MessageBoard';
	//scene.add(mbPlane);

	onWindowResize();
	window.addEventListener('resize', onWindowResize, false);
	renderer.domElement.addEventListener('pointermove', onPointerMove, false);
	renderer.domElement.addEventListener('pointerdown', onPointerDown, false);
	renderer.domElement.addEventListener('pointerup', onPointerUp, false);
	renderer.domElement.addEventListener('pointerleave', onPointerLeave, false);
	renderer.domElement.addEventListener('click', onClick, false);

	if (mapMode === 'heightMap' || mapMode === 'heightMapV2') {

		// create a div element
		const menuDiv = document.createElement('div');
		menuDiv.id = 'menu';

		// Add the div element to the document body
		document.body.appendChild(menuDiv);

		// Create a button element
		const button = document.createElement('button');
		button.textContent = 'Dynamic Button';

		// Add a click event listener
		button.addEventListener('click', () => {
			addMapHelper();
		});

		// Append the button to the container
		menuDiv.appendChild(button);
	}

	console.log('scene', scene);

}



// Helper function to load textures
function loadTexture(url) {
	return new Promise((resolve, reject) => {
		const loader = new TextureLoader();
		loader.load(
			url,
			(texture) => resolve(texture), // Resolve on successful load
			undefined,                     // Optional: Progress callback
			(error) => reject(error)       // Reject on error
		);
	});
}

// Helper function to load tiles
function loadTile(url) {
	return new Promise((resolve, reject) => {
		const loader = new TextureLoader();
		loader.load(
			url,
			(texture) => resolve(texture), // Resolve on successful load
			undefined,                     // Optional: Progress callback
			(error) => reject(error)       // Reject on error
		);
	});
}

function refreshAdressList() {
	addressSuggest();
}

async function addressSuggest(elem) {

	//console.log ('address_suggest (+)');

	var list = document.getElementById("resultsContainer");
	list.innerHTML = '';

	var search_value = document.getElementById("adres").value;

	// starting searching when > 3 characters have been entered
	if (search_value.length > 2) {

		list.style.display = "block";

		let response = await fetch('https://api.pdok.nl/bzk/locatieserver/search/v3_1/suggest?q=' + search_value);
		let myJson = await response.json();
		console.log(myJson);

		var ul = document.createElement('ul');
		ul.setAttribute('id', 'adressen');

		document.getElementById('resultsContainer').appendChild(ul);

		for (const key in myJson.response.docs) {
			//console.log(myJson.response.docs[key].type);
			//console.log(myJson.response.docs[key].weergavenaam);
			var li = document.createElement('li');
			li.setAttribute('id', myJson.response.docs[key].id);
			li.setAttribute('class', 'item');

			ul.appendChild(li);

			var addresId = myJson.response.docs[key].id;
			var a = document.createElement("a");
			a.href = "javascript: getAddress('" + addresId + "', '" + myJson.response.docs[key].type + "', `" + myJson.response.docs[key].weergavenaam + "`);";

			a.textContent = myJson.response.docs[key].weergavenaam;
			li.appendChild(a);
		}
	} else {
		list.style.display = "none";
	}

	//console.log('addressSuggest (-)');
}

async function getAddress(p_id, p_search_type, p_search_value) {

	console.log('getAddress (+)');

	console.log('p_id: ' + p_id);
	var getAddressUrl = 'https://api.pdok.nl/bzk/locatieserver/search/v3_1/lookup?id=' + p_id;

	let response = await fetch(getAddressUrl);
	let myJson = await response.json();
	console.log(myJson);

	console.log('adres coordinates');
	console.log(myJson.response.docs[0].centroide_ll);
	//let bm = await init (myJson.response.docs[0].centroide_ll);

	// Get the full URL
	const fullUrl = window.location.href;
	const url = new URL(fullUrl);
	baseMap = url.searchParams.get('baseMap');

	window.location.href = '?mapMode=customMap&city=nederland&baseMap=' + baseMap + '&maxZoom=18&searchCoordinates=' + myJson.response.docs[0].centroide_ll;

	var list = document.getElementById("resultsContainer");
	list.style.display = "none";

	console.log('getAddress (-)');
}
window.getAddress = getAddress;

function renderDivToTexture() {

	html2canvas(document.querySelector("#messageBoardContainer"), {
		backgroundColor: 'green' // Set custom background color
	}).then(canvas => {

		mbPlane.material = new MeshBasicMaterial({ map: mbTexture });
		mbTexture.needsUpdate = true; // Ensure texture updates

	});

}

function renderHTMLToCanvas() {

	mbContext.fillStyle = 'green'; // Green background
	mbContext.fillRect(0, 0, mbCanvas.width, mbCanvas.height); // Clear with green
	mbContext.fillStyle = 'white'; // Text color
	mbContext.font = '16px Arial';
	mbContext.fillText(messageBoardContainer.textContent, 150, 50); // Example dynamic content

}

async function getBagInfo(identificatie) {

	console.log('getBagInfo (+)');

	console.log('identificatie: ' + identificatie);

	if (identificatie === undefined) {
		return 'Geen informatie beschikbaar';
	}	

	let jsonBAGPand;
	let bagInfo;

	try {
		await fetch('https://www.3dtilesnederland.nl/api/bag_pand_api_direct.php?identificatie=' + identificatie, {
			method: 'get'
		})
			.then(response => response.text())
			.then(jsonData => jsonBAGPand = jsonData)
			.catch(err => {
				//error block
				debug('call_api no result: ' + err)
			}
			)

		if (jsonBAGPand) {
			jsonBAGPand = JSON.parse(jsonBAGPand);

			bagInfo =
				'Type verblijfsobject: ' + jsonBAGPand.pand.identificatie + '\n' +
				'Domein: ' + jsonBAGPand.pand.domein + '\n' +
				'Oorspronkelijk bouwjaar: ' + jsonBAGPand.pand.oorspronkelijkBouwjaar + '\n' +
				'Status: ' + jsonBAGPand.pand.status + '\n'
				;
		}

		console.log('bagInfo: ', bagInfo);

	} catch (error) {
		console.log('Error in getBagInfo: ', error);
		return 'Geen informatie beschikbaar';
	}

	console.log('getBagInfo (-)');

	return bagInfo;
}

function getFeatureIdentifier(intersects) {

	try {
		const intersectObject = intersects[0].object;
		const intersectVertexIndex = intersects[0].face.a;

		// get uuid
		const uuid = intersectObject.uuid;

		// searcf for uuid in the scene
		let foundObject = scene.getObjectByProperty('uuid', uuid);

		// get parent object as this object contains the batchTable information
		let parentFoundObject = foundObject.parent;
		//console.log ('parentFoundObject: ', parentFoundObject);

		const batchIdAttribute = foundObject.geometry.getAttribute('_batchid');
		//console.log ('batchIdAttribute: ', batchIdAttribute);
		//console.log ('batchIdAttribute.count: ', batchIdAttribute.count);

		const batchId = batchIdAttribute.getX(intersectVertexIndex);
		//console.log ('batchId: ', batchId);

		if ( parentFoundObject.batchTable.header.identificatie ) {
			const batchTableIdentificatie = parentFoundObject.batchTable.header.identificatie;  // this is the array containing all the features
			//console.log(`Feature bij batchId ${batchId}:`, batchTableIdentificatie[batchId]);
			return { 'identificatie': batchTableIdentificatie[batchId].replace("NL.IMBAG.Pand.", "")};
		} else {
			return 0;
		}	
	}
	catch (error) {
		return 0;
	}
	
}

function onClick(event) {

	// Calculate mouse position in normalized device coordinates (-1 to +1) for both components
	mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
	mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

	console.log('mouse: ', mouse);

	// Update the raycaster with the camera and mouse position
	raycaster.setFromCamera(mouse, camera);

	console.log('raycaster: ', raycaster);

	// Calculate objects intersecting the ray
	const intersects = raycaster.intersectObjects(scene.children, true);

	if (intersects.length > 0) {

		// Get the object's position
		const position = new Vector3();
		const clickedObject = intersects[0].object;

		clickedObject.getWorldPosition(position);
		console.log('Intersects Array: ', intersects);
		console.log('Position:', position);
		console.log('Scene: ', scene);
		console.log('Clicked Object:', clickedObject);

		const feature = getFeatureIdentifier(intersects);
		console.log('feature: ', feature);

		marker.position.copy(position);
		marker.visible = true;

	}

}

function onWindowResize() {

	if (params.showSecondView) {

		camera.aspect = 0.5 * window.innerWidth / window.innerHeight;
		renderer.setSize(0.5 * window.innerWidth, window.innerHeight);

	} else {

		camera.aspect = window.innerWidth / window.innerHeight;
		renderer.setSize(window.innerWidth, window.innerHeight);

		secondRenderer.domElement.style.display = 'none';

	}
	camera.updateProjectionMatrix();
	renderer.setPixelRatio(window.devicePixelRatio * params.resolutionScale);

}

async function reinstantiateTiles(url) {

	console.log('reinstantiateTiles (+)');

	//	if (tiles) {
	//		geospatialRotationParent.remove(tiles.group);
	//		tiles.dispose();
	//	}

	// Note the DRACO compression files need to be supplied via an explicit source.
	// We use unpkg here but in practice should be provided by the application.
	const dracoLoader = new DRACOLoader();
	dracoLoader.setDecoderPath('https://unpkg.com/three@0.153.0/examples/jsm/libs/draco/gltf/');

	const ktx2loader = new KTX2Loader();
	ktx2loader.setTranscoderPath('https://unpkg.com/three@0.153.0/examples/jsm/libs/basis/');
	ktx2loader.detectSupport(renderer);

	const cityName = activeCity; // Dynamische stadsnaam

	const cityData = Object.entries(tilesetLayers.cities)
		.filter(([key]) => key === cityName)
		.map(([_, cityData]) => ({
			tilesets: cityData.tilesets,
			offset: cityData.offset
		}))[0]; // [0] om het eerste en enige resultaat te verkrijgen

	console.log(cityData);

	cityData.tilesets.forEach((layer, index) => {

		console.log('layer.url: ' + layer.url);

		const tiles = new TilesRenderer(layer.url);

		// Registreer de plugins
		tiles.registerPlugin(new ImplicitTilingPlugin());

		const debugOptions = {
			displayBoxBounds: params.displayBoxBounds,
			displaySphereBounds: params.displaySphereBounds,
			displayRegionBounds: params.displayRegionBounds,
			colorMode: NONE,
			maxDebugDepth: - 1,
			maxDebugDistance: - 1,
			maxDebugError: - 1,
			customColorCallback: null,
		};
		tiles.registerPlugin(new DebugTilesPlugin(debugOptions));

		// set camera resolution
		tiles.setCamera(camera);
		tiles.setResolutionFromRenderer(camera, renderer);

		// set the second renderer to share the cache and queues from the first
		// performance optimization
		if (index > 0) {

			tiles.lruCache = tilesRenderers[0].lruCache;
			tiles.downloadQueue = tilesRenderers[0].downloadQueue;
			tiles.parseQueue = tilesRenderers[0].parseQueue;

		}

		const loader = new GLTFLoader(tiles.manager);
		loader.setDRACOLoader(dracoLoader);
		loader.setKTX2Loader(ktx2loader);
		//loader.register(() => new GLTFCesiumRTCExtension());

		tiles.fetchOptions.mode = 'cors';
		tiles.manager.addHandler(/\.gltf$/, loader);
		geospatialRotationParent.add(tiles.group);

		
		tiles.addEventListener('load-tile-set', () => {

			console.log('layer: ', layer);
			console.log('tiles.group: ', tiles.group);

			// Get the bounding box of the current tileset
			const tilesetBoundingBox = new Box3();
			tiles.getOrientedBoundingBox(tilesetBoundingBox, geospatialRotationParent.matrix);

			console.log('tilesetBoundingBox:', tilesetBoundingBox);

			// Calculate the size and center of the bounding box
			const boxSize = box.getSize(new Vector3());
			const boxCenter = box.getCenter(new Vector3());

			console.log('layer.offset.x, layer.offset.z, layer.offset.y: ', layer.offset.x, layer.offset.z, layer.offset.y);	
			geospatialRotationParent.matrix.decompose(geospatialRotationParent.position, geospatialRotationParent.quaternion, geospatialRotationParent.scale);
			geospatialRotationParent.position.set(layer.offset.x, layer.offset.z, layer.offset.y);
			geospatialRotationParent.quaternion.invert();
			geospatialRotationParent.scale.set(1, 1, 1);
			geospatialRotationParent.updateMatrixWorld(true);
			geospatialRotationParent.position.multiplyScalar(1);

			console.log('geospatialRotationParent.matrixWorld: ', geospatialRotationParent.matrixWorld);
			console.log('geospatialRotationParent.position: ', geospatialRotationParent.position);

			console.log('Tileset has finished loading');

		});

		// update options
		tiles.errorTarget = params.errorTarget;
		tiles.errorThreshold = params.errorThreshold;
		tiles.optimizeRaycast = params.optimizeRaycast;
		tiles.displayActiveTiles = params.displayActiveTiles;
		tiles.maxDepth = params.maxDepth;
		tiles.colorMode = parseFloat(params.colorMode);

		// Voeg het tiles-object toe aan de array
		tilesRenderers.push(tiles);
		geospatialRotationParents.push(geospatialRotationParent);

	})

	console.log('reinstantiateTiles (-)');

}

function formatDate(timestamp) {
	const date = new Date(timestamp);

	const options = {
		weekday: 'long',  // Full weekday name
		year: 'numeric',  // Full year
		month: 'long',    // Full month name
		day: 'numeric',   // Day of the month
		hour: '2-digit',  // 2-digit hour
		minute: '2-digit', // 2-digit minute
		second: '2-digit', // 2-digit second
	};

	return date.toLocaleString('en-US', options).replace(',', '');
}

function getHeightFromDisplacementMap(x, z) {
    // Normalize (x, z) to the [0, 1] UV space of the texture
    const size = 2048; // Size of the plane
    const halfSize = size / 2;
    const u = (x + halfSize) / size;
    const v = (z + halfSize) / size;

    // Ensure u and v are within [0,1]
    if (u < 0 || u > 1 || v < 0 || v > 1) return 0;

	console.log ('osmParent1: ', osmParent1);
	console.log ('osmParent1.children.length: ', osmParent1.children.length)

	if (osmParent1.children.length > 0) {
		const firstChild = osmParent1.children[0];
		console.log('First child:', firstChild);
	}

    // Get pixel data from the displacement map
    const width = 256; //osmParent1[0].material.displacementMap.image.width;
    const height = 256; //osmParent[0].material.displacementMap.image.height;

    const i = Math.floor(u * width);
    const j = Math.floor(v * height);

    const pixelIndex = (j * width + i) * 4; // RGBA, so 4 channels

    // Assuming the displacement map is grayscale, so R = G = B
    const pixelData = displacementMap.image.data; // Uint8Array
    const heightValue = pixelData[pixelIndex] / 255; // Normalize to [0,1]

    return heightValue * material.displacementScale;
}


function animate() {

	renderer.setAnimationLoop(animate);

	orbitControls.update();


	// update messageboard
	const messageBoardDiv = document.getElementById('messageBoardContainer');
	messageBoardDiv.innerHTML = formatDate( Date.now() ); // Set MB text

	// HTML to Canvas
	//renderHTMLToCanvas();                   // Render updated content to canvas
	//mbTexture.needsUpdate = true; 

	if (mapMode === 'mapView') {
		osmCoords = { ...UnitsUtils.datumsToSpherical(activeCityLookatPosition.x, activeCityLookatPosition.y, activeCityLookatPosition.z) };
		openStreetMap.position.set(-osmCoords.x, 0, osmCoords.y);
		openStreetMap.updateMatrixWorld(true);
		openStreetMap.needsUpdate = true;
	}

	if (debugPositions) {
		const headsetCamera = renderer.xr.getCamera();
		console.log(`Headset Position: X=${headsetCamera.position.x}, Y=${headsetCamera.position.y}, Z=${headsetCamera.position.z}`);
		console.log(`Controller Position: X=${controller1.position.x}, Y=${controller1.position.y}, Z=${controller1.position.z}`);
		console.log(`OpenStreetMap Position: X=${openStreetMap.position.x}, Y=${openStreetMap.position.y}, Z=${openStreetMap.position.z}`);
	}

	offsetParent.scale.set(scaleControlValue, scaleControlValue, scaleControlValue);
	offsetParent.position.set(offsetPositionX, offsetPositionZ, offsetPositionY);
	offsetParent.rotation.set(0, 0, Math.PI / 1);

	if (params.up === '-Z') {

		offsetParent.rotation.x = Math.PI / 2;

	} else if (params.up === '+Z') {

		offsetParent.rotation.x = - Math.PI / 2;

	}

	offsetParent.updateMatrixWorld(true);

	// positioneer alle renderers
	tilesRenderers.forEach((tilesRenderer) => {

		const box = new Box3();

		tilesRenderer.getBoundingBox(box)
		const boxCenter = box.getCenter(tilesRenderer.group.position);

		const currentCenterECEF = boxCenter.clone();
		let [longitude, latitude, altitude] = utils.ECEFToLLA(currentCenterECEF.x, currentCenterECEF.y, currentCenterECEF.z);
		let localProjected = UnitsUtils.datumsToSpherical(longitude, latitude);

		tilesRenderer.group.position.set(osmECEFcoordinates[0], osmECEFcoordinates[1], osmECEFcoordinates[2]);

		tilesRenderer.group.position.multiplyScalar(-1)
		tilesRenderer.group.scale.set(1, 1, 1);
		tilesRenderer.group.updateMatrixWorld(true);

		const plugin = tilesRenderer.getPluginByName('DEBUG_TILES_PLUGIN');
		plugin.displayBoxBounds = params.displayBoxBounds;
		plugin.displaySphereBounds = params.displaySphereBounds;
		plugin.displayRegionBounds = params.displayRegionBounds;
		plugin.colorMode = parseFloat(params.colorMode);

	})

	raycaster.setFromCamera(mouse, camera);
	raycaster.firstHitOnly = parseFloat(params.raycast) === FIRST_HIT_ONLY;

	// Update the camera
	camera.updateMatrixWorld();

	// Check if updates are enabled
	if (params.enableUpdate) {
		tilesRenderers.forEach((renderer, index) => {

			renderer.errorTarget = params.errorTarget;
			renderer.update();
			renderer.group.updateMatrixWorld(true);

		});
	}

	render();

}


function onPointerLeave(e) {

	lastHoveredElement = null;

}

function onPointerMove(e) {

	const bounds = this.getBoundingClientRect();
	mouse.x = e.clientX - bounds.x;
	mouse.y = e.clientY - bounds.y;
	mouse.x = (mouse.x / bounds.width) * 2 - 1;
	mouse.y = - (mouse.y / bounds.height) * 2 + 1;

	lastHoveredElement = this;

}

function onPointerDown(e) {

	const bounds = this.getBoundingClientRect();
	startPos.set(e.clientX - bounds.x, e.clientY - bounds.y);

}

function onPointerUp(e) {

	const bounds = this.getBoundingClientRect();
	endPos.set(e.clientX - bounds.x, e.clientY - bounds.y);
	if (startPos.distanceTo(endPos) > 2) {

		return;

	}

}

function isSelecting(controller) {

	// this code is for showing the intersection point of the controller with the map while pressing the frontbutton
	tempMatrix.identity().extractRotation(controller.matrixWorld);

	raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
	raycaster.ray.direction.set(0, 0, - 1).applyMatrix4(tempMatrix);

	// when intersects with tileset
	const results = raycaster.intersectObject(tilesRenderers[0].group, true);
	//console.log ('results: ', results);
	if (results.length) {
		
		const feature = getFeatureIdentifier(results);
		//console.log ('feature: ', feature);

		updateText( 'BAG ID: ' + feature.identificatie + '\ndistance: '+ results[0].distance.toFixed(1) + ' meter');

		const closestHit = results[0];
		const point = closestHit.point;
		rayIntersect.position.copy(point);

		// If the display bounds are visible they get intersected
		if (closestHit.face) {

			intersection = results[0].point;
			intersectionobject = closestHit;
			intersectionobjecttype = '3DOBJECT';

			const normal = closestHit.face.normal;
			normal.transformDirection(closestHit.object.matrixWorld);
			rayIntersect.lookAt(
				point.x + normal.x,
				point.y + normal.y,
				point.z + normal.z
			);

		}

		rayIntersect.visible = true;
	}

	if (intersection === undefined) {
		// basemap intersects
		let intersects
		if (mapMode === 'mapView') {
			intersects = raycaster.intersectObjects([openStreetMap]);
		} else if (mapMode === 'customMap') {
			intersects = raycaster.intersectObjects([mapView]);
		} else if (mapMode === 'heightMap') {
			intersects = raycaster.intersectObjects([OSM]);
		} else {
			intersects = raycaster.intersectObjects([emptyPlaneMesh]);
		}
		if (intersects.length > 0) {
			// global variable which is read in onSelectEnd
			intersection = intersects[0].point;
			intersectionobject = intersects;
			intersectionobjecttype = 'MAP';
		}
	}

	if (intersection === undefined) {
		const object = raycaster.intersectObjects([tilesRenderers[tilesetLayers.cities[activeCity].insertsectObjectLayer].group]);
		if (object.length > 0) {
			console.log('intersects controller 0 distance: ', object[0].distance);
			intersection = object[0].point;
			intersectionobject = object[0];
			intersectionobjecttype = 'DISTANCE';
		}
	}
	
}


function render() {

	//console.log ('render (+)');

	intersection = undefined;

	// right controller
	if (controller0.userData.isSelecting === true) {

		isSelecting (controller0);

	}

	// left controller
	if (controller1.userData.isSelecting === true) {

		isSelecting (controller1);

	}

	if (intersection) {
		marker.position.copy(intersection);
	}

	// show pointer just below the basemap
	marker.position.y = marker.position.y + 0.05;
	marker.visible = intersection !== undefined;

	renderer.render(scene, camera);

	////console.log ('render (-)');

}

function changeBaseMap (val) {

	console.log ('changeBaseMap (+)');

	console.log ('Value: ', val);

	const url = new URL(window.location.href);
	url.searchParams.set('baseMap', val); // Voegt de parameter toe of wijzigt bestaande

	window.location.href = url.toString(); // Redirect naar de nieuwe URL
	
	console.log ('changeBaseMap (-)');

}

function initGui() {

	const gui = new dat.GUI()
	window.gui = gui;
	gui.close();


	// Get the full URL
	const fullUrl = window.location.href;
	const url = new URL(fullUrl);
	baseMap = url.searchParams.get('baseMap');

	// Object om de waarde op te slaan
	const settings = {
		baseMap: baseMap // Default
	};

	// Voeg de dropdown toe met keuzemogelijkheden
	gui.add(settings, "baseMap", ["openStreetMap", "luchtFoto", "BGT"])
		.name("Basemap") // Naam in de GUI
		.onChange(function (value) {
			console.log("Geselecteerde basemap:", value);
			changeBaseMap(value); // Roep een functie aan om de kaartlaag te wisselen
		});

	const tiles3d = gui.addFolder('3D Tiles');

	const scaleControl = {
		scale: scaleControlValue
	};

	const geometricErrorControl = {
		geometricError: params.errorTarget
	};

	const controlOffsetPositionX = {
		x: offsetPositionX
	};

	const controlOffsetPositionY = {
		y: offsetPositionY
	};

	const controlOffsetPositionZ = {
		z: offsetPositionZ
	};

	
	// Add the control for scale the tileset
	tiles3d.add(scaleControl, 'scale', -10, 10, 0.01).name('Scale').onChange(function (value) {
		// Update the scale of the geospatialRotationParent
		scaleControlValue = value;
	});

	// Add the control for geometric Error the tileset
	tiles3d.add(geometricErrorControl, 'geometricError', 0, 256, 1).name('Geometric Error').onChange(function (value) {
		// Update the scale of the geospatialRotationParent
		params.errorTarget = value;
	});
	
	// Add the control for X position of the tileset
	tiles3d.add(controlOffsetPositionX, 'x', -600, 600, 0.1).name('X position').onChange(function (value) {
		offsetPositionX = value;
	});

	// Add the control for Y position of the tileset
	tiles3d.add(controlOffsetPositionY, 'y', -600, 600, 0.1).name('Y position').onChange(function (value) {
		offsetPositionY = value;
	});

	// Add the control for Z position of the tileset
	tiles3d.add(controlOffsetPositionZ, 'z', -600, 600, 0.1).name('Z position').onChange(function (value) {
		offsetPositionZ = value;
	});

	tiles3d.add(params, 'displayRegionBounds').name('Display Region Bounds').onChange(function (value) {
		// Code to handle the change in displayRegionBounds
		console.log('displayRegionBounds changed to:', value);
	});

}

function addMapHelper() {

	console.log('addMapHelper (+)');

	function addGUIForMaterials(materials, tiles, gui) {
		if (materials.length === 0) {
			console.warn('No materials provided for GUI controls.');
			return;
		}

		// Create a folder for global material controls
		const folder = gui.addFolder('Global Material Controls');

		// Create an object to store global settings
		const globalSettings = {
			displacementScale: materials[0].displacementScale || 0,
			displacementBias: materials[0].displacementBias || 0,
			wireframe: materials[0].wireframe || false,
		};

		// Add displacementScale control
		folder.add(globalSettings, 'displacementScale', 0, 2000, 1).name('Displacement Scale').onChange((value) => {
			materials.forEach((material) => {
				material.displacementScale = value;
				material.needsUpdate = true;
			});
		});

		// Add displacementBias control
		folder.add(globalSettings, 'displacementBias', -1000, 1000, 1).name('Displacement Bias').onChange((value) => {
			materials.forEach((material) => {
				material.displacementBias = value;
				material.needsUpdate = true;
			});
		});

		// Add wireframe control
		folder.add(globalSettings, 'wireframe').name('Wireframe').onChange((value) => {
			materials.forEach((material) => {
				material.wireframe = value;
				material.needsUpdate = true;
			});
		});

		const planePropertiesFolder = gui.addFolder("PlaneGeometry")
		planePropertiesFolder.add(planeData, 'width', 1, 512, 1).onChange(() => regeneratePlaneGeometry(tiles));
		planePropertiesFolder.add(planeData, 'height', 1, 512, 1).onChange(() => regeneratePlaneGeometry(tiles));
		planePropertiesFolder.add(planeData, 'widthSegments', 1, 360, 1).onChange(() => regeneratePlaneGeometry(tiles));
		planePropertiesFolder.add(planeData, 'heightSegments', 1, 360, 1).onChange(() => regeneratePlaneGeometry(tiles));

		// Create GUI controls for each rotation axis in terms of π fractions
		const rotationFolder = gui.addFolder("rotationControls");

		rotationFolder.add(rotationControls, 'rotationX', -Math.PI * 2, Math.PI * 2, 0.0001).name('Rotate X').onChange(updateQuaternionFromGUI);
		rotationFolder.add(rotationControls, 'rotationY', -Math.PI * 2, Math.PI * 2, 0.0001).name('Rotate Y (π)').onChange(updateQuaternionFromGUI);
		rotationFolder.add(rotationControls, 'rotationZ', -Math.PI * 2, Math.PI * 2, 0.0001).name('Rotate Z (π)').onChange(updateQuaternionFromGUI);
		rotationFolder.add(rotationControls, 'rotationW', -6, 6, 0.0001).name('Quaternion W').onChange(updateQuaternionFromGUI);

		folder.open();
	}

	// Update group rotation based on GUI values
	function updateGroupRotation() {
		offsetParent.rotation.set(rotationControls.rotationX, rotationControls.rotationY, rotationControls.rotationZ);
	}


	function updateQuaternionFromGUI() {
		// Convert the π fractions to radians
		const x = rotationControls.rotationX * Math.PI;
		const y = rotationControls.rotationY * Math.PI;
		const z = rotationControls.rotationZ * Math.PI;

		console.log('updateQuaternionFromGUI x: ', x);

		// Set quaternion based on Euler angles
		const quaternion = new Quaternion();
		quaternion.setFromEuler(new Euler(x, y, z));

		// Apply the user-defined w component
		quaternion.w = rotationControls.rotationW;

		// Normalize to ensure a valid rotation
		quaternion.normalize();

		//console.log ('offsetParent: ', offsetParent);

		//offsetParent.rotation.set(x, y, z); //
		console.log('osmParent1: ', osmParent1);

		osmParent1.quaternion.copy(quaternion);
		//osmParent.quaternion.set (quaternion.x, quaternion.y, quaternion.z, quaternion.w);
		osmParent1.updateMatrixWorld(true);

	}

	const rotationControls = {
		rotationX: 0, //-4.329780281177466e-17,  // orig 0: Controls rotation as a fraction of π around the X-axis
		rotationY: 0, //0.7071067811865475,  // orig 0: Controls rotation as a fraction of π around the Y-axis
		rotationZ: 0, //0.7071067811865476,  // orig 0: Controls rotation as a fraction of π around the Z-axis
		rotationW: 1 // 4.329780281177467e-17   // orig: 1: W component of the quaternion
	};


	function regeneratePlaneGeometry(tiles) {

		console.log('regeneratePlaneGeometry (+)');

		console.log('planeData.width: ' + planeData.width);
		let newGeometry = new PlaneGeometry(
			planeData.width, planeData.height, planeData.widthSegments, planeData.heightSegments
		)

		tiles.forEach((tile) => {
			console.log('tile: ', tile);
			tile.geometry.dispose()
			tile.geometry = newGeometry
		})
		console.log('regeneratePlaneGeometry (-)');

	}

	//console.log('osmMaterials: ', osmMaterials);
	console.log('osmMaterials.length: ', osmMaterials.length);
	console.log('osmTiles.length: ', osmTiles.length);

	// Use raycast to find all materials and update variables with GUI
	if (osmMaterials.length > 0) {
		addGUIForMaterials(osmMaterials, osmTiles, window.gui);
	} else {
		console.log('No materials with displacementMap found in the scene.');
	}

	console.log('scene: ', scene);

	console.log('addMapHelper (-)');


}